<template>
    <div class="admin-invite-page">
        <router-link to="/prospects" class="btn btn-light mb-3">
            <span class="fas fa-chevron-left mr-1"></span>
            Back
        </router-link>
        <div class="row">
            <div class="col-md-8">
                <ui-card title="Add new prospect" description="Enter the information requested below.">
                    <form id="form-cards-add" @submit.prevent="registerProspect">
                        <prospect-form ref="prospectForm" />
                        <div class="text-end">
                            <ui-button
                                type="submit"
                                variant="primary"
                                :loading="sendingInvitations"
                            >
                                Register
                            </ui-button>
                        </div>
                    </form>
                </ui-card>
            </div>
        </div>
    </div>
</template>

<script>
import ProspectForm from '@/components/prospects/Form';
import UiButton from '@/components/ui/buttons/Button';
import UiCard from '@/components/ui/Card';

export default {
    components: {
        ProspectForm,
        UiButton,
        UiCard,
    },
    data() {
        return {
            sendingInvitations: false,
        };
    },
    methods: {
        async registerProspect() {
            try {
                const prospectForm = this.$refs.prospectForm;

                if (!await prospectForm.validate()) {
                    return;
                }
                
                this.sendingInvitations = true;

                const invitations = [];
                const { emails, role } = prospectForm.formData;

                emails.forEach(email => {
                    invitations.push({
                        type: 'signup',
                        email,
                        role,
                    });
                });

                await this.$axios.post('/_/invitations', invitations);

                this.$router.push('/admins')
                this.$toast.success('Invitations sent successfully.');
            } catch (error) {
                this.$toast.error(this.getErrorMessage(error));
            } finally {
                this.sendingInvitations = false;
            }
        },
    },
};
</script>
